// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-api-mdx": () => import("./../../../../src/api.mdx" /* webpackChunkName: "component---src-api-mdx" */),
  "component---src-debug-mdx": () => import("./../../../../src/debug.mdx" /* webpackChunkName: "component---src-debug-mdx" */),
  "component---src-examples-mdx": () => import("./../../../../src/examples.mdx" /* webpackChunkName: "component---src-examples-mdx" */),
  "component---src-introduction-mdx": () => import("./../../../../src/introduction.mdx" /* webpackChunkName: "component---src-introduction-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-usage-with-react-mdx": () => import("./../../../../src/usage-with-react.mdx" /* webpackChunkName: "component---src-usage-with-react-mdx" */)
}

